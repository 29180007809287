<template>
    <div class="page1">
        <div>
            角色名称：{{role_name}}
        </div>
        <div class="mt20 bgf2 pd20">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全国</el-checkbox>
            <div v-for="(city,index) in list"  class=" ml20 mt10" :key="index">
                <el-checkbox :label="city.area_code"  v-model="city.checked" @change="handleCheckedCitiesChange(city)">
                    {{city.area_name}}
                </el-checkbox>
                <div class="ml20 mt10">
                    <el-checkbox :label="item.area_code"  v-for="(item,sindex) in city.children"
                                 class="mb20" v-model="item.checked" @change="cityCheck(item.checked,city)"
                                 :key="sindex">
                        {{item.area_name}}
                    </el-checkbox>
                </div>
            </div>
        </div>
        <div class="mt20">
            <el-button @click="$router.go(-1)">取消</el-button>
            <el-button type="primary" @click="saveArea">确定</el-button>

        </div>
    </div>
</template>

<script>
  import STable from '@/components/STable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'

  export default {
    name: 'Login',
    data() {
      return {
        role_name: '',
        role_id: '',
        list: [],
        checkAll: '',
        checkedCities: [],
        puList:[],
      }
    },
    watch: {},
    created() {

      let data = this.$route.query
      this.role_id = data.role_id
      this.role_name = data.role_name
      this.roleAreaDetail()

    },
    components: {STable, EDialog, EButton},
    beforeMount() {

    },
    methods: {
      async roleAreaDetail() {
        let res = await Http.roleAreaDetail({role_id: this.role_id})
        // this.list = res.data.list
        let list= res.data.list
        let arr=[]
        let checkedList=[]
        list.forEach((item)=>{
          this.list.push(item)
          arr.push(item)
          if(item.checked){
            checkedList.push(item)
          }
          if(item.children){
            item.children.forEach((sub)=>{
              arr.push(sub)
              if(sub.checked){
                checkedList.push(sub)
              }
            })
          }
        })
        if(checkedList.length==arr.length){
          this.checkAll=true
        }


        this.$forceUpdate()
      },
      handleCheckedCitiesChange(city) {
        if(city.checked){
          city.children.forEach((item)=>{
           item.checked=true
          })
        }else{
          city.children.forEach((item)=>{
            item.checked=false
          })
          this.checkAll=false
        }
      },
      cityCheck(item,city){
        if(!item){
          city.checked=false
        }else{
          let arr=[]
          city.children.forEach((item)=>{
            if(item.checked){
              arr.push(item)
            }
          })
          if(arr.length==city.children.length){
            city.checked=true
          }
        }
      },
      handleCheckAllChange() {
        if(this.checkAll){
            this.list.forEach((item)=>{
              item.checked=true
              if(item.children){
                item.children.forEach((sub)=>{
                  sub.checked=true
                })
              }
            })
        }else{
          this.list.forEach((item)=>{
            item.checked=false
            if(item.children){
              item.children.forEach((sub)=>{
                sub.checked=false
              })
            }
          })
        }
      },
      async saveArea(){
        let res = await Http.editRoleArea({data:JSON.stringify({list:this.list,role_id:this.role_id})})
        if(res.flag==1){
          this.$message.success(res.msg)
          this.$router.go(-1)
        }

      }
    }
  }
</script>

<style lang="scss">

</style>

